<template>
	<div id="disclaimer">
        <v-head></v-head>
        <div class="container">
            <div class="banner">
                <h1 class="title">使用條款及免責聲明</h1>
            </div>
            <div class="content">
                <div class="title">版權告示</div>
                <section>
                    <p>1. 登入「香港中醫在綫」（www.ectcm.com）(下稱「本站」）或其任何網頁，即表示你已無條件接受此版權告示的條款，以及在毋須事先通知你的情況下而可隨時作出的修改及／或增補。請定期瀏覽此網頁，查看此版權告示任何可作出的修改及／或增補。</p>
                </section>
                <section>
                    <h2>版權擁有的範圍</h2>
                    <p>2. 除另有註明外，本站所載的內容皆受本站所擁有的版權約束</p>
                </section>
                <section>
                    <h2>本站所載的非文字內容</h2>
                    <p>3. 若你想透過任何方法複製、分發或以其他方式使用本站內任何非文字內容（包括但不限於照片、圖像、圖畫、圖片、音頻檔案和影像檔案）作任何用途，須事先得到本站的書面同意。有關申請可電郵至本站支援熱綫。</p>
                    <p>4. 若本站內的非文字內容涉及由第三者擁有的版權，在複製、分發或以其他方式使用任何此等非文字內容前，必須先取得版權擁有者的授權或同意。</p>
                </section>
                <section>
                    <h2>本站所載的文字内容</h2>
                    <p>5. 本站網站內受本站版權保護的文字內容可以任何形式或媒體免費複製和分發，作個人用途或供機構內部使用，惟須符合下列條件：</p>
                    <ol>
                        <li>
                            <p>(a) 複製本不得出售或換取任何利益、得益、利潤或報酬，或用作任何其他商業用途；「商業用途」包括但不限於以下用途：</p>
                            <dl>
                                <dt><p>在任何業務過程中或為促進任何業務:</p></dt>
                                <dd><p>(i) 要約供應貨品、服務、設施、土地或土地權益的用途；</p></dd>
                                <dd><p>(ii) 要約提供商業機會或投資機會的用途；</p></dd>
                                <dd><p>(iii) 宣傳或推廣貨品、服務、設施、土地或土地權益的用途；</p></dd>
                                <dd><p>(iv) 宣傳或推廣商業機會或投資機會的用途；</p></dd>
                                <dd><p>(v) 為貨品、服務、設施、土地或土地權益的供應者或準供應者作宣傳或推廣用途；以及</p></dd>
                                <dd><p>(vi) 為商業機會或投資機會的提供者或準提供者作宣傳或推廣用途；</p></dd>
                            </dl>
                        </li>
                        <li>
                            <p>(b) 文字內容必須準確地被複製，並不得用以對本站的任何精神權利有不利影響；及</p>
                        </li>
                        <li>
                            <p>(c) 必須註明本站為該文字內容的版權擁有人，並註明該文字內容的來源為「香港中醫在綫」。</p>
                        </li>
                    </ol>
                    <p>6. 第5條所述的准許只適用於本站內受本站版權保護的文字內容。本站有權於任何時間及在毋須事先通知你的情況下撤回第5條所述的准許。</p>
                    <p>7. 若你想透過第5條所述以外的方法複製、分發或以其他方式使用本站內任何受本站版權保護的文字內容，或作第5條所述准許以外的任何用途，須事先得到本站的書面同意。有關申請可電郵至本站支援熱綫。</p>
                    <p>8. 若本站所載的文字內容涉及第三者版權，在複製、分發或以其他方式使用該文字內容前，必須獲得版權擁有人的授權或准許。</p>
                </section>
                <section>
                    <h2>連結網站所載内容</h2>
                    <p>9. 為免生疑問，第5條所述准許並不引伸至其他與本站連結的網站的任何內容。若你想複製、分發或以其他方式使用該等連結網站的任何內容，必須獲得有關版權擁有人一切所需的授權或准許。</p>
                </section>
                <section class="foot">
                    <h2>免責聲明</h2>
                    <p>本站的資訊只作一般用途，並非專業意見，也不代表這些是全面的資訊。所有本站內所提供的資料及資訊或透過本站的健康問答咨詢平臺或論壇板平臺與醫護人員之通信及/或討論交流 (包括但不限於在討論區發表的意見)只屬表達常見健康及醫療問題有關之一般資訊，並非對任何案件或客戶病人的醫學意見，診斷或治療，亦不能替代專業醫學意見，診斷或治療或可以依賴的意見。請勿基於本站的資料及資訊而延誤或避免尋求獨立醫學意見或治療。</p>
                    <p>本站部分內容是以即時上載留言的方式運作，本站對所有留言的真實性、完整性，合法性（包括該留言的内容有否觸犯任何香港條例或侵犯任何第三者的知識產權權利或版權），及所表示的立場等，不負任何法律責任。而一切留言之言論只代表留言者個人意見，並非本站之立場。由於本站的健康問答討論區受到「即時上載留言」運作方式所規限，故不能完全監察所有留言。若讀者發現有留言出現問題，請聯絡我們。</p>
                </section>
            </div>
        </div>
	</div>
</template>

<script>
    import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead
		}
	};
</script>

<style lang="scss" scoped>
    #disclaimer {
        width: 100%;
        padding-top: 94px;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
        background-color: #EDEDED;
        display: flex;
        flex-direction: column;
    }

    .banner {
        width: 100%;
        height: 146px;
        background-image: url(../../../assets/imgs/mobile/img_background@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .banner > .title {
        font-size: 48px;
        font-weight: bold;
        color: #236271;
        margin-top: 45px;
        line-height: 42px;
        position: relative;
    }

    .banner > .title::before {
        content: '';
        position: absolute;
        left: -80px;
        top: 21px;
        width: 70px;
        height: 1px;
        background-color: #8DD2E3;
    }

    .banner > .title::after {
        content: '';
        position: absolute;
        right: -80px;
        top: 21px;
        width: 70px;
        height: 1px;
        transform: translateY(-50%);
        background-color: #8DD2E3;
    }

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }

    .content {
        flex: 1;
        background-color: #fff;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding: 44px 25px;
        width: 100%;
        box-sizing: border-box;
    }

    .content h2 {
        font-weight: bold;
        font-size: 34px;
        color: #242020;
        line-height: 40px;
    }

    .content p {
        color: #636363;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
    }

    .content .title {
        color: #242020;
        font-size: 42px;
        font-weight: bold;
        line-height: 42px;
        margin-bottom: 50px;
        text-align: center;
    }

    .content section {
        margin-bottom: 20px;
    }

    .content section ol,
    .content section dl {
        margin-left: 24px;
    }

    .content .foot h2 {
        text-align: center;
        margin-bottom: 50px;
        color: #242020;
        font-size: 42px;
        font-weight: bold;
    }
    
    .content .foot p {
        margin-bottom: 37px;
    }
</style>
